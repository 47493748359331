window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

import GLightbox from 'glightbox';
const lightbox = GLightbox({
  touchNavigation: true,
  loop: true,
  autoplayVideos: true
});

window.addEventListener("load", function() {
  const loader = document.querySelector(".loader");
  loader.className += " hidden";
});

console.log(' %c WEBSITE BY JWS.WALES ', 'color: white; background: #1249d6; font-size: 30px; border-radius: 50px;');


// var scrollpos = window.scrollY;
// var header = document.getElementById("header");

// function add_class_on_scroll() {
//     header.classList.add("scrolled-nav");
// }

// function remove_class_on_scroll() {
//     header.classList.remove("scrolled-nav");
// }

// window.addEventListener('scroll', function(){ 
//     //Here you forgot to update the value
//     scrollpos = window.scrollY;

//     if(scrollpos > 50){
//         add_class_on_scroll();
//     }
//     else {
//         remove_class_on_scroll();
//     }
//     console.log(scrollpos);
// });

// var gsap = require("gsap/dist/gsap").gsap;

// let animation = gsap.timeline({repeat:20})
// let targets = document.querySelectorAll(".rotator div")
// let numberOfTargets = targets.length

// let duration = 0.4
// let pause = 1.25

// let stagger = duration + pause
// let repeatDelay = (stagger * (numberOfTargets - 1)) + pause

// function init() {
//   gsap.set(".rotator", {autoAlpha:1})
//   animation.from(targets, {y:-80, duration:duration, opacity:0, stagger:{
//     each:stagger,
//     repeat:-1,
//     repeatDelay:repeatDelay
//   }})
//      .to(targets, {y:80, duration:duration, opacity:0, stagger:{
//     each:stagger,
//     repeat:-1,
//     repeatDelay:repeatDelay
//   }}, stagger)
// }
// //click anywhere to pause
// // window.addEventListener("click", () => animation.paused(!animation.paused()))

// init();